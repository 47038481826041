import React, { useEffect, useState } from "react";
import CommonButton from "../Components/global-components/CommonButton/CommonButton";
import BannerImage from "../Components/login-components/banner-image/BannerImage";
import BottomCard from "../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../Components/login-components/logo-container/LogoContainer";
import MsHome from "../Components/Maruti-Suzuki-components/MsHome";
import PreviewDocument from "../Screens/KYCHomeScreen/Frames/PreviewDocument";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import truncate from "truncate";
import DeleteIcon from "@mui/icons-material/Delete";
import { fileUpload, previewIamgeURL } from "../Screens/Redux/otherDocsSlice";
import { coAuthorisedUpload } from "../services/corporate-authorisedUpload-service";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Loadingoverlay from "../Components/global-components/LoadingOverlay/Loadingoverlay";
import { showGlobalError } from "../Screens/Redux/globalErrorSlice";

const CorporateUploadScreen = () => {
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [frontpreview, setFrontPreview] = useState(false);
  const [backPreview, setBackPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const radioValue = useSelector((state) => state.corporateSlice.radioValue);
  const ckycNumber = useSelector((state) => state.corporateSlice.ckycNumber);
  const cinNumber = useSelector((state) => state.corporateSlice.cinNumber);
  const { frontImageURL, backImageURL } = useSelector(
    (state) => state.uploadedFile.previewImage
  );

  const selectedValue = useSelector(
    (state) => state.dropDownValue.selectedValue
  );

  const frontFileName = useSelector(
    (state) => state.uploadedFile.documentImage.frontFile
  );
  const backFileName = useSelector(
    (state) => state.uploadedFile.documentImage.backFile
  );
  const individualPanNumber = useSelector(
    (state) => state.corporateSlice.individualPanNumber
  );

  const formSixty = useSelector(
    (state) => state.uploadedFile.formSixtyDocument
  );
  const formSixtyName = useSelector(
    (state) => state.uploadedFile.formSixtyDocumentName
  );
  const kycId = useSelector((state) => state.corporateSlice.addNewKycId);
  const istoggle = useSelector((state) => state.switchButton.activeSwitch);

  const typeOfProprietor = useSelector((state) => state.corporateSlice.proprietorRadioValue);
  const selectedEntity = useSelector((state) => state.corporateSlice.selectedValue);

  const {
    permanentAddress,
    permanentPincode,
    permanentCity,
    permanentState,
    correspondenceAddressLineOne,
    correspondenceAddressLineTwo,
    correspondenceState,
    correspondenceCity,
    correspondencePincode,
  } = useSelector((state) => state.corporateSlice.userData);
  const {
    isAryaLiteServiceUsed,
  } = useSelector((state) => state.corporateSlice.panUserdata);
  let convertedFiles = async () => {
    let frontImageConversionToFile = null;
    let file = null;
    await fetch(frontImageURL)
      .then((res) => res.blob())
      .then((blob) => {
        frontImageConversionToFile = blob;
        let blobToFileFront = new File([blob], frontFileName, {
          type: frontImageConversionToFile.type,
        });
        file = blobToFileFront;
      });
    return file;
  };

  let formSixtyFileConverter = async () => {
    let formSixtyConversionToFile = null;
    let file = null;
    await fetch(formSixty)
      .then((res) => res.blob())
      .then((blob) => {
        formSixtyConversionToFile = blob;
        let blobFileToFormSixty = new File([blob], formSixtyName, {
          type: formSixtyConversionToFile.type,
        });
        file = blobFileToFormSixty;
      });
    return file;
  };
  const validateImage = () => {
    if (frontFileName && backFileName) {
      return false;
    }
    return true;
  };

  const frontFileUploadHandler = (e) => {
    if (e.target.files[0]?.type === "application/pdf") {
      return dispatch(
        showGlobalError(
          "PDF is not allowed. Supported file formats are png, jpeg, jpg and heic."
        )
      );
    }
    if(!['image/webp','image/jpeg','image/png'].includes(e.target.files[0]?.type)){
      // console.log("webp file")
      // setDialogOpen(false);
      return dispatch(showGlobalError("Supported file formats are png, jpeg, webp"));
    }
    if (e.target.files[0]) {
      dispatch(fileUpload({ frontFile: e.target.files[0].name }));
      setFrontImage(e.target.files[0]);
      dispatch(
        previewIamgeURL({
          frontImageURL: URL.createObjectURL(e.target.files[0]),
        })
      );
    }
  };
  const frontDeleteHandler = () => {
    dispatch(fileUpload({ frontFile: "" }));
    dispatch(previewIamgeURL({ frontImageURL: "" }));
  };
  const backFileUploadHandler = (e) => {
    if (e.target.files[0]?.type === "application/pdf") {
      return dispatch(
        showGlobalError(
          "PDF is not allowed. Supported file formats are png, jpeg, jpg and heic."
        )
      );
    }
    if(!['image/webp','image/jpeg','image/png'].includes(e.target.files[0]?.type)){
      // console.log("webp file")
      // setDialogOpen(false);
      return dispatch(showGlobalError("Supported file formats are png, jpeg, webp"));
    }
    if (e.target.files[0]) {
      dispatch(fileUpload({ backFile: e.target.files[0].name }));
      setBackImage(e.target.files[0]);
      dispatch(
        previewIamgeURL({
          backImageURL: URL.createObjectURL(e.target.files[0]),
        })
      );
    }
  };
  const backDeleteHandler = () => {
    dispatch(fileUpload({ backFile: "" }));
    dispatch(previewIamgeURL({ backImageURL: "" }));
  };

  const coMetaDataUpdate = () => {
    let data = JSON.stringify({
      kyc_id: kycId,
      permanentAddress: permanentAddress  || "",
      permanentPincode:permanentPincode || "",
      permanentState: permanentState || "",
      permanentCity:permanentCity || "",
      correspondenceAddressOne: correspondenceAddressLineOne || permanentAddress,
      correspondenceAddressTwo: correspondenceAddressLineTwo  || "",
      correspondenceCity:correspondenceCity ||permanentCity || "" ,
      correspondencePincode:correspondencePincode || permanentPincode || "",
      correspondenceState:correspondenceState || permanentState || "",
      isAryaLiteServiceUsed
    });
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/customer/update-customer-meta`,
      headers: {
        "Content-Type": "application/json",
        txnId: localStorage.getItem("txnId") === "null" ?? null,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response?.data?.success === true) {
          navigate("/Success", {
            state: {
              kyc_ref_id: response?.data?.data?.kyc_id,
              successUrl: response?.data?.data?.success_url,
              typeOfKyc: "ovd",
              isRouteAllowed: true,
            },
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const successHandler = () => {
    if (validateImage()) return;
    setIsLoading(true);
    let myFormData = new FormData();
    convertedFiles().then((frontBlobToFile) => {
      myFormData.append("front", frontImage || frontBlobToFile);
      myFormData.append("back", backImage);
      myFormData.append("pan", individualPanNumber);
      myFormData.append("kyc_id", kycId);
      myFormData.append("typeOfDocument", selectedValue);
      myFormData.append(
        "isAadhaarMasked",
        selectedValue === "maskedAadhaar" ? true : false
      );
      if(selectedEntity==="Proprietorship"){
        myFormData.append("typeOfProprietor", typeOfProprietor || "");
      }

      if (true) {
        coAuthorisedUpload(myFormData)
          .then((response) => {
            if (response?.success === true) {
              setIsLoading(false);
              coMetaDataUpdate();
            }
          })
          .catch(function (error) {
            setIsLoading(false);
          });
      }
    });
  };

  const formSixtySubmitHandler = async () => {
    setIsLoading(true);
    if (validateImage()) return;
    let myFormData = new FormData();
    await formSixtyFileConverter().then((formSixtyFile) => {
      myFormData.append("formSixty", formSixtyFile);
    });

    await convertedFiles().then((frontBlobToFile) => {
      myFormData.append("front", frontImage || frontBlobToFile);
      myFormData.append("back", backImage);
    });
    myFormData.append("kyc_id", kycId);
    myFormData.append("typeOfDocument", selectedValue);
    myFormData.append(
      "isAadhaarMasked",
      selectedValue === "maskedAadhaar" ? true : false
    );

    if (true) {
      coAuthorisedUpload(myFormData)
        .then((response) => {
          if (response?.success === true) {
            setIsLoading(false);
            coMetaDataUpdate();
          }
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    }
  };

  const openFrontImageHandler = () => {
    setFrontPreview(true);
  };
  const openBackImageHandler = () => {
    setBackPreview(true);
  };
  const closePreview = () => {
    setFrontPreview(false);
    setBackPreview(false);
  };

  const downloadFrontHandler = () => {
    saveAs(frontImageURL, "image.jpg");
  };

  const downloadBackHandler = () => {
    saveAs(backImageURL, "image.jpg");
  };

  const ovdSubmitHandler = () => {
    if (istoggle) {
      successHandler();
    } else {
      formSixtySubmitHandler();
    }
  };
  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward();
      }, 0);
    };
  }, []);
  return (
    <>
      {isLoading ? (
        <Loadingoverlay />
      ) : (
        <MsHome>
          <BannerImage>
            <LogoContainer />
          </BannerImage>
          <BottomCard max>
            <div className="h-full ">
              <div className="hidden ">
                <div className="p-[10px]"></div>
                <label className="md:text-xl">
                  <b>KYC Document</b>
                </label>
                <p className="hidden md:block md:text-sm">
                  let's fetch your kyc details using
                </p>
              </div>
              <div className="md:w-[60%] md:shadow-lg md:rounded-lg md:p-4 md:h-[98%] overflow-auto h-full w-full md:relative">
                {frontImageURL === "" ? (
                  <Button
                    className="lato-family"
                    sx={{
                      border: "1px solid var(--primary-color)",
                      backgroundColor: "var(--secondary-color)",
                      color: "black",
                      width: "100%",
                    }}
                    component="label"
                  >
                    Upload Front Side
                    <input
                      id="front_image"
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={frontFileUploadHandler}
                    />
                  </Button>
                ) : (
                  <div className="flex border justify-between  p-4">
                    <div>
                      <FileCopyIcon />
                    </div>
                    <div>
                      <span>{truncate(frontFileName, 10)}</span>
                    </div>
                    <div className="flex flex-row gap-5">
                      <VisibilityIcon onClick={openFrontImageHandler} />

                      {frontpreview && (
                        <PreviewDocument
                          imgUrl={frontImageURL}
                          closePreview={closePreview}
                        />
                      )}
                      <DownloadIcon onClick={downloadFrontHandler} />
                      <DeleteIcon onClick={frontDeleteHandler} />
                    </div>
                  </div>
                )}
                <div className="p-4"></div>
                {backFileName === "" ? (
                  <Button
                    sx={{
                      border: "1px solid var(--primary-color)",
                      backgroundColor: "var(--secondary-color)",
                      color: "black",
                      width: "100%",
                    }}
                    component="label"
                  >
                    Upload Back Side
                    <input
                      id="back_img"
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={backFileUploadHandler}
                    />
                  </Button>
                ) : (
                  <div className="flex border justify-between p-4">
                    <div>
                      <FileCopyIcon />
                    </div>
                    <div>
                      <span>{truncate(backFileName, 10)}</span>
                    </div>
                    <div className="flex flex-row gap-5">
                      <VisibilityIcon onClick={openBackImageHandler} />
                      {backPreview && (
                        <PreviewDocument
                          imgUrl={backImageURL}
                          closePreview={closePreview}
                        />
                      )}
                      <DownloadIcon onClick={downloadBackHandler} />
                      <DeleteIcon onClick={backDeleteHandler} />
                    </div>
                  </div>
                )}

                <div className="w-full  left-0   bottom-4 z-50  mt-8  md:p-4  md:w-[100%] md:mr-auto md:ml-0 md:mt-10 md:absolute md:bottom-4 md:left-0  md:right-0 ">
                  <CommonButton
                    label="Submit"
                    filled
                    arrow
                    id={"upload_docs_img"}
                    onClickHandler={ovdSubmitHandler}
                  />
                </div>
              </div>
              {/* </div> */}
              <div className=" mt-3 md:p-0" />
            </div>
          </BottomCard>
        </MsHome>
      )}
    </>
  );
};

export default CorporateUploadScreen;
